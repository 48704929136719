// extracted by mini-css-extract-plugin
export var container = "a0f1x3D-";
export var containerPadding = "_1+uwl8Rb";
export var slideInAnimation = "xFybxYVA";
export var gcsLogoContainer = "oy7aC6G8";
export var gcsLogo = "A04n6qkC";
export var subContainer = "O3atVipc";
export var ybLogo = "LeJ4bXzd";
export var loadingSvg = "_3VUfq96C";
export var title = "Mhsum4rb";
export var uspsContainer = "t8QblmuI";
export var uspContainer = "aoowpQ7m";
export var gcsUspTitle = "xKe+JYmZ";
export var gcsUspContent = "XFLQ8Lig";
export var tick = "_40SIvWfD";