import React, { useState, useEffect } from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, PreviewBody, Spinner } from 'components';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import queryString from 'query-string';
import axios from 'axios';
import { ReactComponent as GetCreditScoreLogo } from '../images/getcreditscore-logo-white.svg';
import { ReactComponent as YouBrokerLogo } from '../images/logo.svg';
import { ReactComponent as Tick } from '../images/tick.svg';
import { isBrowser } from '../utils/isBrowser';
import * as styles from './gcs.module.scss';

const URL = `${process.env.GATSBY_SITE_URL}/api/gcs`;

const GCS = ({ location }) => {
  const {
    gcsSettings: { data: settingsData },
  } = useStaticQuery(gcsSettingsQuery);

  const { gcs_redirect_title: gcsRedirectTitle, gcs_usps: gcsUsps } = settingsData;

  const [isPreviewEnv, setIsPreviewEnv] = useState(false);

  useEffect(() => {
    const { PrismicToolbar } = window;

    setIsPreviewEnv(!!PrismicToolbar);
  }, []);

  const handleNavigation = (success) => {
    if (success) return navigate('/get-credit-score');
    return navigate('/get-started?from_gcs=true');
  };

  const handleApplicantData = async () => {
    if (isBrowser) {
      const applicantGcsid = queryString.parse(location?.search)?.gcsid;

      if (!applicantGcsid) return null;

      try {
        const response = await axios.post(URL, {
          applicantGcsid,
        });
        const responseData = response?.data;

        if (!responseData) return null;

        const { localStorageData, gcsData } = formatGcsData(responseData);

        localStorage.setItem('yb-data', JSON.stringify(localStorageData));
        localStorage.setItem(
          'yb-gcs-data',
          JSON.stringify({
            gcsid: applicantGcsid,
            gcsData,
          })
        );

        return true;
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  };

  useEffect(async () => {
    const gcsSuccess = await handleApplicantData();

    setTimeout(() => {
      handleNavigation(gcsSuccess);
    }, 2000);
  }, []);

  if (isPreviewEnv) {
    return (
      <Layout location={location}>
        <PreviewBody />
      </Layout>
    );
  }

  return (
    <div className={styles.container}>
      <div className={`wrapper ${styles.containerPadding}`}>
        <div className={styles.gcsLogoContainer}>
          <GetCreditScoreLogo className={styles.gcsLogo} />
        </div>
        <div className={styles.subContainer}>
          <YouBrokerLogo className={styles.ybLogo} />
          <Spinner />
          <div dangerouslySetInnerHTML={{ __html: gcsRedirectTitle?.html }} className={styles.title} />
          <div className={styles.uspsContainer}>
            {gcsUsps?.map((usp) => (
              <div className={styles.uspContainer} key={usp?.title?.html}>
                <Tick className={styles.tick} />
                <div dangerouslySetInnerHTML={{ __html: usp?.gcs_usp_title?.html }} className={styles.gcsUspTitle} />
                <div
                  dangerouslySetInnerHTML={{ __html: usp?.gcs_usp_content?.html }}
                  className={styles.gcsUspContent}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPrismicUnpublishedPreview(GCS);

export const formatGcsData = (data) => {
  const { firstName, mobile, email, homeLoanPreferences, scoreBandName, ...restOfData } = data;
  const preferences = homeLoanPreferences?.preferences;

  const formatCreditScoreBand = {
    EXCELLENT: 'Excellent',
  };

  const localStorageData = {
    additionalApplicants: [
      {
        firstName,
      },
    ],
    phoneNumber: mobile,
    emailAddress: email,
    purchaseOrRefinance: homeLoanPreferences?.intention === 'Buy' ? 'Purchase' : 'Refinance',
    expectedPurchasePrice: Number(preferences?.houseValue),
    loanAmount: Number(preferences?.oweAmount),
    propertyUse: preferences?.propertyPurpose === 'Live' ? 'To live in' : "It's an investment",
    depositAmount: preferences?.deposit,
    creditHistory: formatCreditScoreBand?.[scoreBandName],
    householdIncome: Number(preferences?.income),
  };

  return {
    localStorageData,
    gcsData: data,
  };
};

export const gcsSettingsQuery = graphql`
  query gcsSettingsQuery {
    gcsSettings: prismicSettings {
      data {
        gcs_redirect_title {
          html
          text
        }
        gcs_usps {
          gcs_usp_content {
            html
            text
          }
          gcs_usp_title {
            html
            text
          }
        }
      }
    }
  }
`;
